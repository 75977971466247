import "./App.scss";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  redirect,
} from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useRootContext } from "./context/context";
import { ToastContainer } from "react-toastify";
import Home from "./components/Home/Home";
import Utils from "./services/Utils";
import ChatBot from "./components/Pages/chat-bot/ChatBot";
import Login from "./components/login/Login";
import Signup from "./components/login/Signup";
import API_Call from "./services/apiCall";
import { useEffect } from "react";
import CommonLoader from "./common-components/loader/CommonLoader";
import Profile from "./components/Pages/Profile/Profile";
import Portfolio from "./components/Portfolio/Portfolio";
import AIBot from "./components/Pages/ai-bot/AIBot";

const App = () => {
  const { showLoader, setDefaultText, defaultText } =
    useRootContext();
  const {
    response: defaultTextResponse,
    error: defaultTextError,
    fetchData: fetchDefaultText,
  } = API_Call({ endpoint: "defaultText" });


  const Routes = [
    {
      path: "/",
      element: <Portfolio />
    },
    {
      path: 'ai-bot',
      element: <AIBot />
    },
    {
      path: "bot",
      element: (
        // <PrivateRoute>
        <Home />
        // </PrivateRoute>
      ),
      children: [
        {
          path: "",
          index: true,
          loader: async () => redirect("ai-bot"),
        },
        {
          path: "ai-bot",
          element: <AIBot />,
        },
        {
          path: "profile",
          element: <Profile />
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
  ];

  const onLoadFetch = () => {
    fetchDefaultText({});
  };

  useEffect(() => {
    onLoadFetch({});
  }, []);

  useEffect(() => {
    if (defaultTextResponse && defaultTextResponse.status === "success") {
      setDefaultText(defaultTextResponse.data);
    }
  }, [defaultTextResponse]);


  const router = createBrowserRouter(Routes);

  return (
    <>
      {showLoader > 0 && <CommonLoader />}
      {defaultText && <RouterProvider router={router} />}
      <ToastContainer />
    </>
  );
};

const PrivateRoute = ({ children }) => {
  const userInfo = Utils.getStorage("userInfo");
  const checkUserAuthentication = () => {
    if (userInfo && userInfo.tenantId) {
      return true;
    }
    return false;
  };
  const isAuthenticated = checkUserAuthentication();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default App;
